input:autofill,
input:-webkit-autofill {
  box-shadow: 0 0 0px 999px white inset;
  border: 0;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  margin: 2px !important;
  margin-left: 2px !important;
  margin-bottom: 2px !important;
  border-radius: 8px;
}

div[role="tab"] {
  overflow: hidden !important;
}

div[index] {
  background: white !important;
}

.prevent-select-img img {
  -webkit-user-drag: none;
  cursor: pointer;
}
/* Remove outline default when focus element have tab index */
div[tabindex]:focus {
  outline: none;
}
body{
  overflow-x: hidden;
  max-width: 800px;
  margin: 0 auto;
}
